// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZekLZqS-2wDdmOKrfWY6hI5u-Eh3s-jM",
  authDomain: "chairs-c5451.firebaseapp.com",
  projectId: "chairs-c5451",
  storageBucket: "chairs-c5451.appspot.com",
  messagingSenderId: "1038688752702",
  appId: "1:1038688752702:web:73d794c7b0f9a685536d6f",
  measurementId: "G-39BFZ807JP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const database = getDatabase(app);
