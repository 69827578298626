import React, { useCallback, useMemo, useEffect } from "react";
import { usePayPalScriptReducer, PayPalButtons } from "@paypal/react-paypal-js";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { confirm } from "../features/confirmationSlice";

export default function PayPal({ total, notes, cart }) {
  const reduxDispatch = useDispatch();
  const [{ options }, paypalDispatch] = usePayPalScriptReducer();
  const { user } = useSelector((state) => state.auth);

  const uuid = useMemo(() => uuidv4(), []);

  useEffect(() => {
    paypalDispatch({
      type: "setLoadingStatus",
      value: "pending",
    });
  }, [total, paypalDispatch]);

  const createOrder = useCallback(
    (data, actions) => {
      // Truncate the item name to ensure it doesn't exceed PayPal's limit
      const truncatedNotes = notes.length > 100 ? notes.substring(0, 97) + '...' : notes;
      
      return actions.order.create({
        intent: "CAPTURE",
        purchase_units: [
          {
            reference_id: uuid,
            amount: {
              currency_code: "USD",
              value: total,
              breakdown: {
                item_total: {
                  currency_code: "USD",
                  value: total,
                },
              },
            },
            items: [
              {
                name: `Chair sets (${truncatedNotes})`,
                unit_amount: {
                  currency_code: "USD",
                  value: total,
                },
                quantity: 1,
              },
            ],
          },
        ],
        application_context: {},
      });
    },
    [total, notes, uuid]
  );

  const onApprove = useCallback(
    (data, actions) => {
      return actions.order.capture().then((details) => {
        reduxDispatch(
          confirm({
            uid: user.uid,
            referenceId: uuid,
            ...details,
            cart,
            status: "PAID",
          })
        );
      });
    },
    [reduxDispatch, user.uid, uuid, cart]
  );

  return (
    <PayPalButtons
      createOrder={createOrder}
      onApprove={onApprove}
      options={{
        disableFunding: "paylater",
      }}
      disabled={!(parseFloat(total) > 0)}
    />
  );
}