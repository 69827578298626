import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
  MenuItem,
} from "@mui/material";
import BeachAccessIcon from "@mui/icons-material/WbSunny";
import { useDispatch, useSelector } from "react-redux";
import { keyframes } from "@mui/system";
import { yellow } from "@mui/material/colors";
import { unwrapResult } from "@reduxjs/toolkit";
import { useFormik } from "formik";
import * as Yup from "yup";
import { signUp, signIn, resetPassword } from "../features/authSlice";
import { TextField } from "@mui/material";

import { auth } from "../firebaseConfig";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const AuthDialog = ({ open: _open, onClose, mode: _mode }) => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(_open);
  const [mode, setMode] = useState(_mode);
  const [submissionFeedback, setSubmissionFeedback] = useState({
    color: "red",
    message: "",
  });

  const [reset, setReset] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    password:
      mode === "signUp" && !reset ? Yup.string().required("Required") : null,
    confirmPassword:
      mode === "signUp"
        ? Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords do not match")
            .required("Required")
        : null,
    unit: mode === "signUp" ? Yup.string().required("Required") : null,
    building: mode === "signUp" ? Yup.string().required("Required") : null,
    fullName: mode === "signUp" ? Yup.string().required("Required") : null,
    phone: mode === "signUp" ? Yup.string().required("Required") : null,
  });
  React.useEffect(() => {
    // if (loading) return setOpen(false);
    // if (!user) return setOpen(true);
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        // You can set the user in your state or context to use it throughout the app
        console.log("User is signed in:");
        setOpen(_open);
      } else {
        // User is signed out.
        console.log("User is signed out");
        setOpen(true);
      }
    });
    return unsubscribe();
  }, [_open, user]);
  React.useEffect(() => {
    setMode(_mode);
  }, [_mode]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      unit: "",
      building: "",
      fullName: "",
      phone: "",
    },
    validationSchema,
    onSubmit: (values, { setErrors }) => {
      const handleClose = () => {
        onClose();
        setErrors({});
      };
      if (reset)
        return dispatch(resetPassword(values.email))
          .then(unwrapResult)
          .then(() => {
            setSubmissionFeedback({
              color: "green",
              message: "Reset link sent (may take up to 10 minutes)",
            });
          })
          .catch((error) => {
            setSubmissionFeedback({ color: "red", message: error });
          });
      if (mode === "signIn") {
        dispatch(signIn(values))
          .then(unwrapResult)
          .then(handleClose)
          .catch((e) => setSubmissionFeedback({ color: "red", message: e }));
      } else {
        dispatch(signUp(values))
          .then(unwrapResult)
          .then(handleClose)
          .catch((e) => setSubmissionFeedback({ color: "red", message: e }));
      }
    },
  });
  const handleClose = () => {
    onClose();
    formik.resetForm();
    setSubmissionFeedback({ color: "red", message: "" });
    setReset(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            sx={{ margin: "auto", marginTop: 1 }}
            onClick={handleClose}
          >
            <BeachAccessIcon
              sx={{
                animation: `${rotate} 10s linear infinite`,
                fontSize: "2em",
                color: yellow[500],
              }}
            />
          </IconButton>
        </Box>
        <DialogTitle color="primary" sx={{ textAlign: "center" }}>
          {mode === "signIn" ? "Sign In" : "Sign Up"}
        </DialogTitle>
        <DialogContent sx={{ padding: "20px 30px" }}>
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          {!reset && (
            <>
              <TextField
                margin="dense"
                label="Password"
                type="password"
                fullWidth
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              {mode === "signUp" && (
                <>
                  <TextField
                    margin="dense"
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />
                  <Typography variant="subtitle">
                    Please Include Additional Info Below
                  </Typography>
                  <Typography variant="caption">
                    <br />
                    (Guests must stay at Tops'l to reserve chairs)
                  </Typography>
                  <TextField
                    margin="dense"
                    label="Full Name"
                    type="text"
                    fullWidth
                    name="fullName"
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                  <TextField
                    margin="dense"
                    label="Unit #"
                    type="text"
                    fullWidth
                    name="unit"
                    value={formik.values.unit}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.unit && Boolean(formik.errors.unit)}
                    helperText={formik.touched.unit && formik.errors.unit}
                  />
                  <TextField
                    margin="dense"
                    label="Tops'l Building"
                    select
                    type="text"
                    fullWidth
                    name="building"
                    value={formik.values.building}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.building && Boolean(formik.errors.building)
                    }
                    helperText={
                      formik.touched.building && formik.errors.building
                    }
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="tides">The Tides</MenuItem>
                    <MenuItem value="summit">The Summit</MenuItem>
                    <MenuItem value="beachManor">Beach Manor</MenuItem>
                    <MenuItem value="tennisVillas">Tennis Villas</MenuItem>
                  </TextField>
                  <TextField
                    margin="dense"
                    label="Phone"
                    type="text"
                    fullWidth
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </>
              )}
            </>
          )}
          {mode === "signIn" && (
            <>
              <Button onClick={() => setReset((prev) => !prev)}>
                {!reset ? "Reset Password" : "Sign In"}
              </Button>
              <Button onClick={() => setMode("signUp")}>Sign Up</Button>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {submissionFeedback && (
            <div style={{ color: submissionFeedback.color, maxWidth: "380px" }}>
              {submissionFeedback.message}
            </div>
          )}
          <Button onClick={onClose} color="primary" disabled={!user}>
            Cancel
          </Button>
          {mode === "signUp" && (
            <Button onClick={() => setMode("signIn")} color="primary">
              Sign In
            </Button>
          )}
          <Button type="submit" color="primary">
            {mode === "signIn"
              ? reset
                ? "Send Reset Email"
                : "Sign In"
              : "Sign Up"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AuthDialog;
