import React from "react";
import { Box, Typography, TextField, useMediaQuery } from "@mui/material";
import { format } from "date-fns";

import { Chairs } from "../icons";

const FiveDayCalendar = ({ handleChange, sets }) => {
  const matches = useMediaQuery("(min-width:425px)");
  // Generate an array with 5 dates
  const start = new Date("2024-08-31");
  const dates = Array.from(
    { length: 5 },
    (_, i) => new Date(start.getTime() + i * 24 * 60 * 60 * 1000),
  );

  const renderComponents = (n) => {
    return Array.from({ length: n }, (_, index) => (
      <Box key={index} sx={{ m: 0.25, display: "inline-flex" }}>
        <Chairs color="primary" />
      </Box>
    ));
  };

  return (
    <Box
      display="flex"
      flexDirection={!matches ? "column" : "row"}
      justifyContent="space-around"
      border={1}
      borderColor="grey.500"
      borderRadius={1}
      m={2}
      mb={0}
    >
      {dates.map((date, i) => (
        <Box
          key={i}
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding={1}
          borderRight={i !== dates.length - 1 ? 1 : 0}
          borderColor="grey.500"
          justifyContent="flex-start"
        >
          <Typography sx={{ mb: 1 }} variant="subtitle1">{`${format(
            date,
            "eee",
          )} ${format(date, "dd")}`}</Typography>

          <TextField
            label="Sets"
            type="text"
            min="0"
            variant="outlined"
            sx={{ maxWidth: 128, "& input": { textAlign: "center" } }}
            value={sets[i]}
            onChange={(e) => handleChange(e, i)}
            pattern="[1-9][0-9]*"
          />

          <Box sx={{ mt: 1 }}>{renderComponents(sets[i])}</Box>
        </Box>
      ))}
    </Box>
  );
};

export default FiveDayCalendar;
