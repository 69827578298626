import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ref, get } from "firebase/database";
import { database } from "../firebaseConfig";

export const getStats = createAsyncThunk(async (_, { rejectWithValue }) => {
  try {
    const statsRef = ref(database, `stats/`);
    const stats = await get(statsRef).then((s) => s.val());
    return stats; // Return the confirmation object
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const statsSlice = createSlice({
  name: "stats",
  initialState: {
    volleyball: [0, 0, 0],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStats.fulfilled, (state, action) => {
        state.volleyball = action.payload.volleyball; // Assuming the data is stored under a "volleyball" property
        state.error = null;
      })
      .addCase(getStats.rejected, (state, action) => {
        state.error = action.payload;
        state.volleyball = [0, 0, 0]; // Reset to initial state or handle as needed
      });
  },
});

export default statsSlice.reducer;
