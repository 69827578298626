import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { onAuthStateChanged } from "firebase/auth";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { authStateChanged } from "./features/authSlice";
import { auth } from "./firebaseConfig";

import { store } from "./store";

onAuthStateChanged(auth, (user) => {
  store.dispatch(authStateChanged(user));
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const paypalOptions = {
  clientId:
    "AfieAStpn16JYoYysF_WWiEIC3SaGq8vskfD3PMvXxnUlNsSyI2HyEeXo6fe_W308awTB0CWeudB3COg",
  // enableFunding: ["venmo"],
  disableFunding: ["paylater", "venmo"],
  intent: "capture",
};

const theme = createTheme();
root.render(
  <React.StrictMode>
    <PayPalScriptProvider options={paypalOptions}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </PayPalScriptProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
