import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ref, set, get } from "firebase/database";
import { database } from "../firebaseConfig";

export const confirm = createAsyncThunk(
  "conf/confirm",
  async (confirmation, { rejectWithValue }) => {
    const { referenceId, uid } = confirmation;
    console.log("confirm", confirmation);
    try {
      const userRef = ref(
        database,
        `users/${uid}/confirmations2024/${referenceId}`,
      );
      await set(userRef, confirmation);
      return confirmation; // Return the confirmation object
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
export const saveCart = createAsyncThunk(
  "conf/saveCart",
  async (cart, { rejectWithValue }) => {
    const { uid } = cart;
    try {
      const userRef = ref(database, `users/${uid}/cart2024`);
      await set(userRef, cart);
      return cart; // Return the confirmation object
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
// export const fetchCart = createAsyncThunk(
//   "conf/fetchCart",
//   async (uid, { rejectWithValue }) => {
//     const { uid } = cart;
//     try {
//       const userRef = ref(database, `users/${uid}/cart`);
//       await set(userRef, cart);
//       return cart; // Return the confirmation object
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   },
// );
export const confSlice = createSlice({
  name: "conf",
  initialState: {
    confirmation: null,
    user: null,
    loading: false,
    error: null,
    passwordResetStatus: "idle",
    passwordResetError: null,
  },
  reducers: {
    resetConf: state => { state.confirmation = null }
  },
  extraReducers: (builder) => {
    builder
      .addCase(confirm.fulfilled, (state, action) => {
        state.confirmation = action.payload;
        state.error = null;
      })
      .addCase(confirm.rejected, (state, action) => {
        state.error = action.payload;
        state.confirmation = null;
      })
      .addCase(saveCart.fulfilled, (state, action) => {
        state.user = action.payload;
        state.error = null;
      })
      .addCase(saveCart.rejected, (state, action) => {
        state.error = action.payload;
        state.user = null;
      });
  },
});

export const { resetConf } = confSlice.actions;

export default confSlice.reducer;
