import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ref, set, get, update } from "firebase/database";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, database } from "../firebaseConfig";

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (credentials, { rejectWithValue }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password,
      ).catch((error) => {
        throw error.message;
      });
      const userSerializable = {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
      };

      const userRef = ref(database, "users/" + userSerializable.uid);
      const userData = await get(userRef)
        .then((s) => s.val())
        .catch((error) => {
          throw error.message;
        });
      return { ...userSerializable, ...userData };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (credentials, { rejectWithValue }) => {
    const { email, password } = credentials;
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      ).catch((error) => {
        throw error.message;
      });
      const userSerializable = {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
      };

      // Add additional user data here
      const additionalUserData = {
        unit: credentials.unit,
        building: credentials.building,
        fullName: credentials.fullName,
        phone: credentials.phone,
      };

      // Create a reference to the location you want to write the data
      const userRef = ref(database, "users/" + userSerializable.uid);

      // Write the data to the database
      await set(userRef, additionalUserData).catch((error) => {
        throw error.message;
      });
      return { ...userSerializable, ...additionalUserData };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async (values, { rejectWithValue }) => {
    try {
      const userSerializable = {
        uid: values.uid,
        email: values.email,
      };

      // Add additional user data here
      const additionalUserData = {
        unit: values.unit,
        building: values.building,
        fullName: values.fullName,
        phone: values.phone,
        email: userSerializable.email
      };

      // Create a reference to the location you want to write the data
      const userRef = ref(database, "users/" + userSerializable.uid);

      // Write the data to the database
      await update(userRef, additionalUserData).catch((error) => {
        throw error.message;
      });
      return { ...userSerializable, ...additionalUserData };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const signOut = createAsyncThunk(
  "auth/signOut",
  async (_, { rejectWithValue }) => {
    try {
      await auth.signOut();
      return null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const authStateChanged = createAsyncThunk(
  "auth/authStateChanged",
  async (user, { rejectWithValue }) => {
    if (user) {
      // User is signed in.
      const userData = {
        uid: user.uid,
        email: user.email,
        // ... other user data
      };

      const userRef = ref(database, "users/" + userData.uid);
      const extraUserData = await get(userRef)
        .then((s) => s.val())
        .catch((error) => {
          throw error.message;
        });
      return { ...userData, ...extraUserData };
    } else {
      // User is signed out.
      return null;
    }
  },
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (email, { rejectWithValue }) => {
    try {
      await sendPasswordResetEmail(auth, email).catch((error) => {
        throw error.message;
      });
      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loading: false,
    error: null,
    passwordResetStatus: "idle",
    passwordResetError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.user = null;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.user = action.payload;
        state.error = null;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.error = action.payload;
        state.user = null;
      })
      .addCase(authStateChanged.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(authStateChanged.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.pending, (state) => {
        state.passwordResetStatus = "loading";
        state.passwordResetError = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.passwordResetStatus = "succeeded";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.passwordResetStatus = "failed";
        state.passwordResetError = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
        unit: action.payload.unit,
        building: action.payload.building,
        fullName: action.payload.fullName,
        phone: action.payload.phone,
        email: action.payload.email
        };
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default authSlice.reducer;

// export const signIn = createAsyncThunk(
//   "auth/signIn",
//   async (credentials, { rejectWithValue }) => {
//     try {
//       const userCredential = await setPersistence(
//         auth,
//         browserSessionPersistence,
//       )
//         .then(() => {
//           // Existing and future Auth states are now persisted in the current
//           // session only. Closing the window would clear any existing state even
//           // if a user forgets to sign out.
//           // ...
//           // New sign-in will be persisted with session persistence.
//           return signInWithEmailAndPassword(
//             auth,
//             credentials.email,
//             credentials.password,
//           );
//         })
//         .catch((error) => {
//           // Handle Errors here.
//           const errorCode = error.code;
//           const errorMessage = error.message;
//         });
//       const userSerializable = {
//         uid: userCredential.user.uid,
//         email: userCredential.user.email,
//       };
//       return userSerializable;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   },
// );
