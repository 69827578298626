import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Badge
} from "@mui/material";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import WarningIcon from "@mui/icons-material/WarningAmber";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { signOut } from "../features/authSlice"; // Adjust the import path as needed

import AuthDialog from "./AuthDialog";
import UpdateInfo from "./UpdateInfo";

const AppToolbar = () => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth); // Update the path based on your store structure
  const [anchorEl, setAnchorEl] = useState(null);
  const [authDialogOpen, setAuthDialogOpen] = useState(false);
  const [authMode, setAuthMode] = useState("signIn");
  const [updateInfo, setUpdateInfo] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    dispatch(signOut());
    handleClose();
  };

  const handleAuthClick = (mode) => {
    setAuthMode(mode);
    setAuthDialogOpen(true);
  };

  const handleAuthClose = () => {
    setAuthDialogOpen(false);
    handleClose();
  };
  
const openUpdateInfo = () => {
    setUpdateInfo(true);
  };
  const closeUpdateInfo = () => {
    setUpdateInfo(false);
    handleClose();
  };
  
  React.useEffect(() => {
    if(!loading && user?.uid && !user.building) {
      setUpdateInfo(true)
    } else {
      setUpdateInfo(false)
    }
  }, [user])

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          <BeachAccessIcon />
        </Typography>
        {user && !user.building
        ?<Badge badgeContent={<WarningIcon sx={{color: '#ed6c02'}}/>}>
          <IconButton onClick={handleClick} color="inherit">
          <AccountCircle />
        </IconButton>
        </Badge>
        : 
        <IconButton onClick={handleClick} color="inherit">
          <AccountCircle />
        </IconButton>}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {user && <MenuItem disabled>{user.email}</MenuItem>}
          {user && <MenuItem onClick={openUpdateInfo} sx={{color: t => user.building ? t.palette.text.primary : '#ed6c02'}}>Update Info</MenuItem>
          }
          {user && <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>}
          {!user && (
            <MenuItem onClick={() => handleAuthClick("signIn")}>
              Sign In
            </MenuItem>
          )}
          {!user && (
            <MenuItem onClick={() => handleAuthClick("signUp")}>
              Sign Up
            </MenuItem>
          )}
        </Menu>
      </Toolbar>
      <AuthDialog
        open={authDialogOpen}
        onClose={handleAuthClose}
        mode={authMode}
      />
      <UpdateInfo
        open={updateInfo}
        onClose={closeUpdateInfo}
      />
    </AppBar>
  );
};

export default AppToolbar;
