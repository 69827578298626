/*global paypal*/
import React from "react";
import "./App.css";
import { useSelector } from "react-redux";

import AppToolbar from "./components/AppToolbar";
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Box,
  InputAdornment,
  FormControl,
  FilledInput,
  InputLabel,
  Button,
  Tooltip,
} from "@mui/material";

// import Volleyball from "./components/Volleyball";
import CheckoutDialog from "./components/Checkout";
import Calendar from "./components/Calendar";
import Layout from "./components/Layout";

const dates = [
  "2024-08-30",
  "2024-08-31",
  "2024-09-01",
  "2024-09-02",
  "2024-09-03",
];

const MyCards = () => {
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const [sets, setSets] = React.useState([0, 0, 0, 0, 0]);
  const [total, setTotal] = React.useState(0);
  const [totals, setTotals] = React.useState([0, 0, 0, 0, 0]);
  const [discounts, setDiscounts] = React.useState([0, 0, 0, 0, 0]);
  const [discountedTotals, setDiscountedTotals] = React.useState([
    0, 0, 0, 0, 0,
  ]);
  const ratePerDay = 63;
  const discount = 6.88;
  const addDiscount = 3.45;

  const toggleCheckout = () => setOpen((prev) => !prev);

  const cart = {
    dates,
    sets,
    totals,
    discounts,
    discountedTotals,
  };

  React.useEffect(() => {
    if (!user) return;
    if (!user.cart2024) return;
    const { cart2024 } = user;
    return setSets(cart2024.sets);
  }, [user]);

  React.useEffect(() => {
    const setsTotaled = sets.map((s, i) => {
      return s * ratePerDay;
    });
    const calcDiscount = sets.map((s, i) => {
      const o = (offset) => sets[i + offset] || 0;
      const twoDay = Math.max(o(+1), o(-1));
      const threeDayForward = Math.min(o(+2), o(+1), s);
      const threeDayBack = Math.min(o(-1), o(-2), s);
      const threeDayMid = Math.min(o(-1), o(+1), s);
      const threeDay = Math.max(threeDayForward, threeDayBack, threeDayMid);
      const d = parseFloat(
        (
          Math.min(s, twoDay) * discount +
          Math.min(s, threeDay) * addDiscount
        ).toFixed(2),
      );
      return d;
    });
    const discountTotals = setsTotaled.map((s, i) => s - calcDiscount[i]);
    const discountTotal = calcDiscount.reduce((total, num) => total + num, 0);
    // console.log(calcDiscount, discountTotal);
    setTotal(
      setsTotaled.reduce((total, num) => total + num, 0) - discountTotal,
    );
    setTotals(setsTotaled);
    setDiscounts(calcDiscount);
    setDiscountedTotals(discountTotals);
  }, [sets]);
  const handleChangeSets = (index, value) => {
    const newSets = [...sets];
    newSets[index] = value;
    setSets(newSets);
  };
  const handleChange = (e, index) => {
    const { value } = e.target;
    if (value < 0) return handleChangeSets(index, "");
    if (isNaN(value)) return handleChangeSets(index, "");
    return handleChangeSets(
      index,
      value ? parseInt(value.replace(/^0+([1-9])/, "$1")) : 0,
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography variant="h6">Aug/Sept 2024</Typography>
      <Calendar handleChange={handleChange} sets={sets} />

      <Typography
        variant="caption"
        sx={{ mt: 0, color: (t) => t.palette.text.secondary }}
      >
        1 Set = 2 Chairs & 1 Umbrella
      </Typography>
      {/*dates.map((date, index) => (
        <Card
          key={index}
          sx={{
            minWidth: "calc(60vw)",
            maxWidth: "calc(90vw)",
            margin: "auto",
            mt: 2,
            paddingBottom: 0,
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              align="center"
              sx={{ mb: 1 }}
            >
              {formatDate(date)}
            </Typography>
            <TextField
              label="No. of sets"
              type="number"
              min="0"
              variant="outlined"
              sx={{ maxWidth: 128, "& input": { textAlign: "center" } }}
              value={sets[index]}
              onChange={(e) => handleChange(e, index)}
            />
            <Box sx={{ mt: 1 }}>{renderComponents(sets[index])}</Box>
          </CardContent>
          <CardActionArea>
            <Typography
              variant="caption"
              sx={{ color: (t) => t.palette.text.secondary }}
            >
              1 Set = 2 Chairs & 1 Umbrella
            </Typography>
          </CardActionArea>
        </Card>
      ))
      <Volleyball />*/}
      <Card
        sx={{
          minWidth: "calc(60vw)",
          maxWidth: "calc(90vw)",
          margin: "auto",
          mt: 2,
          paddingBottom: 0,
          mb: 3,
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            align="center"
            sx={{ mb: 1 }}
          >
            Total
          </Typography>
          <FormControl
            sx={{ maxWidth: 128, "& input": { textAlign: "center" } }}
            variant="filled"
          >
            <InputLabel htmlFor="filled-adornment-amount">Amount</InputLabel>
            <FilledInput
              id="filled-adornment-amount"
              value={total.toFixed(2)}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
        </CardContent>
        <CardActionArea component="div">
          {user && user.uid ? user.building ? (
            <Button onClick={toggleCheckout}>Reserve Sets</Button>
          ) : (
            <Tooltip title="Update Info to Continue (Top Right)">
              <span>
                <Button disabled>Reserve Sets</Button>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Sign in to Reserve Seats (Top Right)">
              <span>
                <Button disabled>Reserve Sets</Button>
              </span>
            </Tooltip>
          )}
        </CardActionArea>
      </Card>
      {/*<Layout segments={10} />*/}
      <CheckoutDialog
        cart={cart}
        total={total}
        open={open}
        onClose={toggleCheckout}
      />
    </Box>
  );
};

function App() {
  return (
    <Box className="App">
      <AppToolbar />
      <Box sx={{ mt: 2 }}>
        <MyCards />
      </Box>
    </Box>
  );
}

export default App;
