import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./features/counterSlice";
import authReducer from "./features/authSlice";
import confReducer from "./features/confirmationSlice";
import statsReducer from "./features/statsSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    conf: confReducer,
    stats: statsReducer,
  },
});
