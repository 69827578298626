import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
  MenuItem,
} from "@mui/material";
import BeachAccessIcon from "@mui/icons-material/WbSunny";
import { useDispatch, useSelector } from "react-redux";
import { keyframes } from "@mui/system";
import { yellow } from "@mui/material/colors";
import { unwrapResult } from "@reduxjs/toolkit";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateUser } from "../features/authSlice";
import { TextField } from "@mui/material";

import { auth } from "../firebaseConfig";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const AuthDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);
  // const [open, setOpen] = useState(_open);
  const [mode, setMode] = useState('signUp');
  const [submissionFeedback, setSubmissionFeedback] = useState({
    color: "red",
    message: "",
  });

  const [reset, setReset] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    // password:
    //   mode === "signUp" && !reset ? Yup.string().required("Required") : null,
    // confirmPassword:
    //   mode === "signUp"
    //     ? Yup.string()
    //         .oneOf([Yup.ref("password"), null], "Passwords do not match")
    //         .required("Required")
    //     : null,
    unit: mode === "signUp" ? Yup.string().required("Required") : null,
    building: mode === "signUp" ? Yup.string().required("Required") : null,
    fullName: mode === "signUp" ? Yup.string().required("Required") : null,
    phone: mode === "signUp" ? Yup.string().required("Required") : null,
  });
  // React.useEffect(() => {
  //   if (loading) return setOpen(false);
  //   // if (!user) return setOpen(false);
  //   if (user && !user.building) {
  //     setOpen(true)
  //   }
  //   return;
  // }, [user]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
        uid: user?.uid,
      email: user?.email || "",
    //   password: "",
    //   confirmPassword: "",
      unit: user?.unit ||"",
      building: user?.building ||"",
      fullName: user?.fullName ||"",
      phone: user?.phone ||"",
    },
    validationSchema,
    onSubmit: (values, { setErrors }) => {
      const handleClose = () => {
        onClose();
        setErrors({});
      };
      dispatch(updateUser(values))
          .then(unwrapResult)
          .then(handleClose)
          .catch((e) => setSubmissionFeedback({ color: "red", message: e }));
    },
  });
  const handleClose = () => {
    onClose();
    formik.resetForm();
    setSubmissionFeedback({ color: "red", message: "" });
    setReset(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            sx={{ margin: "auto", marginTop: 1 }}
            onClick={handleClose}
          >
            <BeachAccessIcon
              sx={{
                animation: `${rotate} 10s linear infinite`,
                fontSize: "2em",
                color: yellow[500],
              }}
            />
          </IconButton>
        </Box>
        <DialogTitle color="primary" sx={{ textAlign: "center" }}>
          Update Info
        </DialogTitle>
        <DialogContent sx={{ padding: "20px 30px" }}>
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          {!reset && (
            <>
              {mode === "signUp" && (
                <>
                  <Typography variant="subtitle">
                    Please review account info below
                  </Typography>
                  <Typography variant="caption">
                    <br />
                    (Guests must stay at Tops'l to reserve chairs)
                  </Typography>
                  <TextField
                    margin="dense"
                    label="Full Name"
                    type="text"
                    fullWidth
                    name="fullName"
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                  <TextField
                    margin="dense"
                    label="Unit #"
                    type="text"
                    fullWidth
                    name="unit"
                    value={formik.values.unit}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.unit && Boolean(formik.errors.unit)}
                    helperText={formik.touched.unit && formik.errors.unit}
                  />
                  <TextField
                    margin="dense"
                    label="Tops'l Building"
                    select
                    type="text"
                    fullWidth
                    name="building"
                    value={formik.values.building}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.building && Boolean(formik.errors.building)
                    }
                    helperText={
                      formik.touched.building && formik.errors.building
                    }
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="tides">The Tides</MenuItem>
                    <MenuItem value="summit">The Summit</MenuItem>
                    <MenuItem value="beachManor">Beach Manor</MenuItem>
                    <MenuItem value="tennisVillas">Tennis Villas</MenuItem>
                  </TextField>
                  <TextField
                    margin="dense"
                    label="Phone"
                    type="text"
                    fullWidth
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </>
              )}
            </>
          )}
          {mode === "signIn" && (
            <>
              <Button onClick={() => setReset((prev) => !prev)}>
                {!reset ? "Reset Password" : "Sign In"}
              </Button>
              <Button onClick={() => setMode("signUp")}>Sign Up</Button>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {submissionFeedback && (
            <div style={{ color: submissionFeedback.color, maxWidth: "380px" }}>
              {submissionFeedback.message}
            </div>
          )}
          <Button onClick={onClose} color="primary" disabled={!user}>
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Update Info
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AuthDialog;
