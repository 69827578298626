import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";

import { confirm } from "../features/confirmationSlice";

const VenmoLink = ({ username, amount, notes }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const venmoURL = `venmo://paycharge?txn=pay&recipients=${username}&amount=${amount}&note=${notes}`;
  const webURL = `https://venmo.com/${username}?amount=${amount}&note=${notes}`;

  return (
    <Button
      href={isMobile ? venmoURL : webURL}
      component="a"
      variant="outlined"
      target="_blank"
      rel="noopener noreferrer"
      className="paypal-button paypal-button-number-1 paypal-button-layout-vertical paypal-button-shape-rect paypal-button-number-multiple paypal-button-env-production paypal-button-color-blue paypal-button-text-color-white paypal-logo-color-white "
    >
      Pay with Venmo
    </Button>
  );
};

const ConfirmationDialog = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  // const { cart, amount: total, notes } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    const confObj = {
      uid: user.uid,
      referenceId: v4(),
      cart: props.cart,
      total: props.amount,
      notes: props.notes,
      status: "PENDING",
    };
    console.log({ confObj });
    dispatch(confirm(confObj));
    setOpen(false);
  };

  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        target="_blank"
        onClick={handleClickOpen}
      >
        Pay with Venmo
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Reservation Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Clicking on the button below will submit a reservation request to
            your account. Receipt of funds will be manually verified and sets
            will be confirmed once payment receipt is processed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Box onClick={handleConfirm}>
            <VenmoLink color="primary" {...props} />
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
