import React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";

import PayPal from "./PayPal";
import { saveCart, resetConf } from "../features/confirmationSlice";
import ConfirmationDialog from "./ConfirmationDialog";



const Tip = ({ handleChange, value }) => (
  <Paper variant="outlined" style={{ padding: 16 }}>
    <Typography sx={{ textAlign: "center", mb: 1 }}>
      Tip for Beach Set Attendants
    </Typography>
    <Grid
      container
      spacing={2}
      sx={{ justifyContent: "center", display: "flex" }}
    >
      <Grid
        item
        xs={7}
        sm={4}
        sx={{ justifyContent: "center", display: "flex" }}
      >
        <TextField
          label="Tip"
          type="number"
          step={1}
          disabled
          value={value}
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid
      item
        xs={12}
        sx={{ textAlign: "center", color: (t) => t.palette.text.secondary }}
      >
        <Typography variant="caption">
          **Beach service requires included tip for groups
        </Typography>
      </Grid>
    </Grid>
  </Paper>
);

const CheckoutDialog = ({ open, onClose, total, cart }) => {
  const { user } = useSelector((state) => state.auth);
  const { confirmation } = useSelector((state) => state.conf);
  // const [volleyball, setVolleyball] = React.useState([0, 0, 0]);
  const [tip, setTip] = React.useState(0);
  const [updatedTotal, setUpdatedTotal] = React.useState(total);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setTip(parseFloat((total * 0.2).toFixed(2)));
  }, [total]);
  // const handleVolleyballChange = (e, i) => {
  //   const newValues = [...volleyball];
  //   newValues[i] = e.target.value;
  //   setVolleyball(newValues);
  // };
  const { dates, sets, totals, discounts, discountedTotals } = cart;
  const notes =
    user &&
    `Name: ${user.fullName} \r\n 
    Unit: ${user.building}-${user.unit} Chair Sets: ${dates
      .map((d, i) => {
        if (!sets[i]) return null;
        const date = new Date(d);
        const f = `${date.getUTCMonth() + 1}/${date.getUTCDate()}`;
        return `[${f}: ${sets[i]}]`;
      })
      .filter(Boolean)} Tip: $ ${tip} UID: ${user.uid}`; // Volleyball: ${JSON.stringify(volleyball)}`;
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  }).format(date);
};
  React.useEffect(() => {
    if (!user) return;
    if (!open) return;
    const cartData = { ...cart, uid: user.uid };
    dispatch(saveCart(cartData));
  }, [open]);
  React.useEffect(() => {
    // const volleyballTotal = parseFloat(
    //   volleyball.reduce((total, num) => total + parseFloat(num || 0), 0),
    // );
    const newTotal = parseFloat(total) + parseFloat(tip); //+ volleyballTotal;
    setUpdatedTotal(newTotal);
  }, [total, tip]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Checkout
        <IconButton
          sx={{ position: "absolute", top: 4, right: 4 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {dates.map((date, i) => {
          if (!sets[i]) return null;
          return (
            <Box key={date} sx={{ textAlign: "center", mb: 2 }}>
              <Typography variant="h6">{formatDate(date)}</Typography>
              <Typography>{`${sets[i]} sets X $63.00 = $${totals[i]} - $${discounts[i]} multiday discount = $${discountedTotals[i]}`}</Typography>
              <Divider />
            </Box>
          );
        })}
        {/*<MyInputGroup
          handleChange={handleVolleyballChange}
          values={volleyball}
        />*/}
        <Tip handleChange={(e) => setTip(e.target.value)} value={tip} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ mr: 1 }}>
            Total:
          </Typography>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              border: `${1}px solid`,
              borderRadius: 1,
              display: "flex",
              alignItems: "center",
              p: 1,
            }}
          >
            $ {updatedTotal.toFixed(2)}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt={2}
        >
          <Typography variant="caption">Venmo Me (no fees)</Typography>
          <Box>
            <ConfirmationDialog
              username="9858594936"
              amount={updatedTotal.toFixed(2)}
              notes={notes}
              cart={cart}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          m={2}
        >
          {confirmation 
          ? <><Alert severity="success">{`Confirmed! Ref#: ${confirmation?.referenceId}`}</Alert>
          <Button onClick={() => dispatch(resetConf())}>Reset Payment</Button></>
          : <><Typography variant="caption">Other options (3.5% Fee)</Typography>
          <PayPal
            total={((updatedTotal + 0.5) / 0.965).toFixed(2)}
            notes={notes}
            cart={cart}
          /></>
          }
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CheckoutDialog;
